import Parse from "parse";
import config from "../config";
import moment from "moment"
const PARSE_SERVER_URL = config.parseServerUrl
const PARSE_APP_ID = config.appId
const PARSE_JAVASCRIPT_KEY = config.javascriptKey
const PARSE_MASTER_KEY = config.masterKey

Array.prototype.sumByKey = function (prop) {
    let sum = this.map(o => prop.split('.').reduce((r, val) => { return r ? r[val] : undefined; }, o)).reduce((a, c) => { return a + c }, 0);
    return sum
}

export const init = async () => {
    Parse.initialize(PARSE_APP_ID, PARSE_JAVASCRIPT_KEY, PARSE_MASTER_KEY);
    Parse.masterKey = PARSE_MASTER_KEY
    Parse.serverURL = PARSE_SERVER_URL
};

export const login = async userInfo => {
    init()
    if (!userInfo.username || !userInfo.password) {
        return {
            error: 'please fill in the input'
        };
    }
    const user = await Parse
        .User
        .logIn(userInfo.username, userInfo.password);
    if (user) {
        var expireTime = Math.round((new Date()).getTime() / 1000);
        expireTime += 14400
        user.set('token', user.sessionToken);
        user.set('expireTime', expireTime);
        await user
            .save()
            .catch(error => {
                return {
                    error: error
                }
            });
        return user
    }
    return {
        error: 'Log in failed'
    }
}
export const closeConnection = async () => {
    init()
    Parse
        .LiveQuery
        .close();
    return
}
export const getAllObjects = async (className, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit || 99999)
    query.skip(skip || 0)
    query.descending("createdAt");
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}
export const getAllReportData = async (className, hotel,dateFilter) => {
    init()
    const startDate = new Date(dateFilter[0].clone().startOf('day').add(7,'hours').toString())
    const endDate = new Date(dateFilter[1].clone().endOf('day').add(7,'hours').toString())
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(999999)
    query.skip(0)
    query.descending("reportDate");
    query.equalTo('hotel', hotel);
    query.greaterThanOrEqualTo('reportDate',startDate );
    query.lessThan('reportDate',endDate );
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}

export const getCurrentGuestRoom = async ( hotel,dateFilter) => {
    init()
    const startDate = dateFilter.clone().startOf('day').add(7,'hours').toDate()
    console.log(`startDate : ${startDate}`);
 
    const ClassName = Parse.Object.extend('GuestReport');
    const query = new Parse.Query(ClassName);
    query.limit(999999)
    query.skip(0)
    query.descending("reportDate");
    query.equalTo('hotel', hotel);
    query.greaterThanOrEqualTo('departureDate',startDate );
    query.lessThanOrEqualTo('arrivalDate',startDate );
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}


export const getTodayReportData = async (className, hotel,dateFilter) => {
    init()
    console.log('dateFilter',dateFilter);
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(999999)
    query.skip(0)
    query.descending("reportDate");
    query.equalTo('hotel', hotel);
    query.contains('reportDateStr', dateFilter.format('YYYY-MM-DD'));
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}

export const getObjectsCount = async (className, limit, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    if (limit) {
        query.limit(limit)
    }
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    const results = await query.count();

    console.log(`results length:${results}`)
    return results
}

export const queryObjects = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    console.log(`results length:`, results)
    return { results: objs, count: count }
}

export const queryObjectsCount = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    return count
}

export const queryObjectsByCreatedAt = async (className, start, end, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    query.greaterThanOrEqualTo('createdAt', start);
    query.lessThan('createdAt', end);

    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.birth = json.birth.iso
        json.expiryDate = json.expiryDate.iso
        objs.push(json)
    })
    console.log(`results length:${results}`)
    return { results: objs, count: count }
}


export const trashObjectWithId = async (className, objectId, trashBool) => {
    init()
    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId)
    if (result) {
        if (trashBool) {
            result.set('isTrash', trashBool)
        } else {
            result.unset('isTrash')
        }

        const res = await result.save().catch(error => {
            return { type: 'error', msg: error.message }
        })
        return { type: 'success', msg: 'Done!' }
    } else {
        return { type: 'error', msg: `Can\'t find ${className} with this ID` }
    }
}

export const getObjectWithId = async (className, objectId) => {
    init()
    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId, { useMasterKey: true })

    console.log(`getObjectWithId :${JSON.stringify(result)}`)
    return result
}
export const destroyObjectWithId = async (className, objectId) => {
    init()

    const Class = Parse.Object.extend(className);
    const query = new Parse.Query(Class);
    const result = await query.get(objectId)

    const res = await result.destroy({useMasterKey:true}).catch((error) => {
        console.log(`destroyObjectWithId error:${JSON.stringify(error)}`)
        return { type: 'error', msg: 'Destroy Error! :' + error.message }
    });
    return { type: 'success', msg: 'Destroy Done!' }

}


export const createObject = async (className, req) => {
    init()
    console.log(`createObject:${JSON.stringify(req)}`)

    const ClassName = Parse.Object.extend(className);
    const obj = new ClassName();
    try {
        const res = await obj.save(req, { useMasterKey: true })
        var acl = new Parse.ACL();
        acl.setPublicReadAccess(true);
        acl.setPublicWriteAccess(true);
        res.setACL(acl);
        await res.save(null, { useMasterKey: true })
        return { type: 'success', msg: 'Done!', objectId: res.id, object: res }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message + ' code: ' + error.code }
    }


}

export const editObject = async (req) => {
    init()
    console.log(`editObject:${JSON.stringify(req)}`)
    await req.save().catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}

export const saveAllObject = async req => {
    init()
    console.log(`saveAllObject req`, req)
    const res = await Parse.Object.saveAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}
export const fetchAllObject = async req => {
    init()
    console.log(`saveAllObject req`, req)
    const res = await Parse.Object.fetchAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return res
}
export const destroyAllObject = async req => {
    init()
    const res = await Parse.Object.destroyAll(req).catch(error => {
        return { type: 'error', msg: 'Error! :' + error.message }
    })
    return { type: 'success', msg: 'Done!' }
}

export const uploadParseFile = async (file, isUseOriginalFileName) => {
    init()
    const uuid = new Date().getTime()
    console.log(`upload uuid :  ${uuid}`)
    const extend = !isUseOriginalFileName ? file.type === 'image/jpeg' ? 'jpg' : 'png' : file.name.split('.').pop();
    var parseFile = new Parse.File(isUseOriginalFileName ? file.name.replace(/ /g, "-").replace(/\(/g, "").replace(/\)/g, "") : `${uuid}.${extend}`, file.originFileObj);
    try {
        const img = await parseFile.save();
        console.log(`upload img.url :  ${img.url()}`)
        const File = Parse.Object.extend("File")
        const obj = new File()
        obj.set('file', img)
        obj.save()
        return { url: img.url(), img: img }
    } catch (error) {
        console.log(`upload error :  ${error.message}`)

        return { type: 'error', msg: 'Error! :' + error.message }

    }
}

export const uploadParseThumbnailFile = async file => {
    init()
    const uuid = new Date().getTime()
    console.log(`upload uuid :  ${uuid}`)
    var parseFile = new Parse.File(`${uuid}.jpg`, { base64: file });
    const img = await parseFile.save().catch(error => {
        console.log(`upload error :  ${error.message}`)

        return { type: 'error', msg: 'Error! :' + error.message }
    })
    console.log(`upload img.url :  ${img.url()}`)
    const File = Parse.Object.extend("Thumbnail")
    const obj = new File()
    obj.set('file', img)
    obj.save()
    return { url: img.url(), img: img }

}

export const queryObjectBySomeKey = async (className, key, value, limit, skip, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    query.equalTo(key, value);
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const getValueById = async (className, key, objectId) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    const result = await query.get(objectId);
    return result.get(key)
}

export const resetPassword = async (objectId, password) => {
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    const user = await query.get(objectId);
    user.set('password', password)
    try {
        await user.save()
        return ({ type: 'success', msg: 'เรียบร้อย' })
    } catch (error) {
        return ({ type: 'error', msg: error.message })
    }
}

export const queryObjects2 = async (className, key, value, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('fromDate', dateFilter[0].startOf('day').toDate());
        query.lessThanOrEqualTo('toDate', dateFilter[1].endOf('day').toDate());
    }
    if (value !== "") {
        query.contains(key, value);
    }
    query.descending("createdAt");
    const count = await query.count();
    const results = await query.find();
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    console.log(`results length:${results}`)
    return { results: objs, count: count }
}

export const getConfig = async () => {
    init()
    const config = await Parse.Config.get()
    return config
}
export const saveConfig = async (data) => {
    init()
    console.log('saveConfig', data)
    const res = await Parse.Config.save(data, { useMasterKey: true }).catch(error => {
        return { type: 'error', msg: error.message }
    })
    return { type: 'success', msg: "Save done!", config: res }

}

export const getCurrentUser = async () => {
    init()
    const user = await Parse.User.current();
    await user.fetch({ useMasterKey: true });
    return user
}

export const adminLogger = async (type, activity, data) => {
    init()
    const user = await getCurrentUser()
    const Activity = Parse.Object.extend('Activity');
    const obj = new Activity()
    obj.set('user', user)
    obj.set('username', user.get('username'))
    obj.set('type', type)
    obj.set('activity', activity)
    if (data) {
        obj.set('data', data)
    }
    try {
        const res = await obj.save()
        return { type: 'success', msg: 'Ok', object: res }
    } catch (error) {
        return { type: 'error', msg: 'Error! :' + error.message }
    }
}

export const checkCustomerRegistered = async (lineId) => {
    init()
    const ClassName = Parse.Object.extend('Customer');
    const query = new Parse.Query(ClassName);
    query.equalTo('lineId', lineId)
    const result = await query.first();
    const contactName = result.get('contactName')
    return contactName ? result : false
}

export const registerCustomer = async (lineId, data) => {
    init()
    const ClassName = Parse.Object.extend('Customer');
    const query = new Parse.Query(ClassName);
    query.equalTo('lineId', lineId)
    const result = await query.first();
    if (result) {
        const res = await result.save(data)
        return res.error ? 'การลงทะเบียนมีปัญหา' : 'success'
    } else {
        return 'ไม่พบผู้ใช้'
    }
}

export const getCustomerByLineId = async (lineId) => {
    init()
    const ClassName = Parse.Object.extend('Customer');
    const query = new Parse.Query(ClassName);
    query.equalTo('lineId', lineId)
    const result = await query.first();
    return result
}

export const getAdmins = async (isTrash) => {
    init()
    const ClassName = Parse.Object.extend('User');
    const query = new Parse.Query(ClassName);
    query.descending("createdAt");
    query.exists('adminLevel');
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}

export const getAllObjectsByDateFilter = async (className, limit, skip, dateFilter, isTrash) => {
    init()
    const ClassName = Parse.Object.extend(className);
    const query = new Parse.Query(ClassName);
    query.limit(limit)
    query.skip(skip)
    query.descending("createdAt");
    if (isTrash) {
        query.equalTo('isTrash', isTrash)
    } else {
        query.doesNotExist('isTrash')
    }
    if (dateFilter.length !== 0) {
        query.greaterThanOrEqualTo('createdAt', new Date(dateFilter[0].startOf('day').toString()));
        query.lessThan('createdAt', new Date(dateFilter[1].endOf('day').toString()));
    }
    const results = await query.find({ useMasterKey: true });
    var objs = []
    results.map((obj) => {
        const json = obj.toJSON()
        json.parseObject = obj
        objs.push(json)
    })
    //console.log(`results length:${objs.length} | ${JSON.stringify(objs[0])}`)
    return objs
}
