export default {
  apiUrl: 'http://yoursite.com/api/',
};
import config from '../config'

const siteConfig = {
  siteName: 'ASIA HOTEL',
  siteIcon: 'ion-flash',
  footerText: 'ASIA HOTEL ©2021 version ' + config.version,
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'english';

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};

export { siteConfig, language, themeConfig, jwtConfig };
