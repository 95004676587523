import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from '../../settings';
import logo from '../../assets/images/asia-removebg.png'
export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              <i className={siteConfig.siteIcon} />
            </Link>
          </h3>
        </div>
      ) : (
          
          <Link to="/dashboard">
            <img src={logo} style={{width: 190, height: 133}} />
          </Link>
      )}
    </div>
  );
};
