import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },{
    path: 'roomMonitor',
    component: asyncComponent(() => import('../RoomMonitor')),
  },
  {
    path: 'forecast',
    component: asyncComponent(() => import('../ForecastReport')),
  },{
    path: 'room-setting',
    component: asyncComponent(() => import('../Room')),
  },{
    path: 'managerReport',
    component: asyncComponent(() => import('../ManagerReport')),
  },{
    path: 'keycardReport',
    component: asyncComponent(() => import('../KeycardReport')),
  },{
    path: 'guestReport',
    component: asyncComponent(() => import('../GuestReport')),
  },{
    path: 'houseKeepingReport',
    component: asyncComponent(() => import('../HouseKeepingReport')),
  },,{
    path: 'housekeeping',
    component: asyncComponent(() => import('../HouseKeepingDashboard')),
  },{
    path: 'user',
    component: asyncComponent(() => import('../AdminUser')),
  },
  {
    path: 'activity',
    component: asyncComponent(() => import('../Activity')),
  },
  
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
