const options = [
  {
    key: '',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-arrow-graph-up-right',
  }, {
    key: 'roomMonitor',
    label: 'Room Monitor',
    leftIcon: 'ion-clipboard',
  }, 
  // {
  //   key: 'forecast',
  //   label: 'Forecast Report',
  //   leftIcon: 'ion-connection-bars',
  // }, 
  {
    key: 'housekeeping',
    label: 'House Keeping',
    leftIcon: 'ion-android-person',
    
  }, {
    key: 'statistic',
    label: 'Statistic Info.',
    leftIcon: 'ion-ios-settings-strong',
    children:[
      {
        key: 'managerReport',
        label: 'Manager Report',
      },{
        key: 'guestReport',
        label: 'Guest Report',
      },{
        key: 'keycardReport',
        label: 'Keycard Report',
      },{
        key: 'houseKeepingReport',
        label: 'House Keeping',
      },
    ]
  },{
    key: 'setting',
    label: 'Setting',
    leftIcon: 'ion-android-settings',
    children:[
      {
        key: 'room-setting',
        label: 'Room Setting',
      },
    ]
  },{
    key: 'user',
    label: 'Admin User',
    leftIcon: 'ion-ios-contact-outline',
  },{
    key: 'activity',
    label: 'Activity Logs',
    leftIcon: 'ion-android-walk',
  }, 
];
export default options;
